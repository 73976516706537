
.BackgroundOptionsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 0.5rem;
}

.BackgroundOption {
    min-width: 100px;
    max-width: 150px;
    height: 160px;
    border-radius: 10px;
    background-color: #555055;
    background-size: cover;
    background-position: center;
    border: #444044 0px solid;
    cursor: pointer;
    flex-grow: 1;
}

.BackgroundOption .check {
    display: none;
}

.BackgroundOption.BackgroundOption-Selected
{
    border: #44aa44 5px solid;
}

.BackgroundOption.BackgroundOption-Selected .check {
    display: block;
    background: #44aa44;
    border: white 3px solid;
    color: white;
    font-weight: bold;
    margin: 10px;
    width: 40px;
    height: 40px;
    line-height: 34px;
    border-radius: 20px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.5) 1px 0px 5px;
}