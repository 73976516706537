.App {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.footer {
  height: 4em;
}

.footer a {
  font-weight: 500;
  color: #444;
  text-decoration: none;
}


.footer a:hover {
  color: black;
  text-decoration: underline;
}

.link {
  cursor: pointer;
  color: var(--bs-blue);
  -webkit-text-decoration-skip: objects;
}

.link:hover {
  text-decoration: underline;
}

.MarketingSpiel-Card {
  background: #f7f7f7;
  padding: 20px;
}

.ShareInstructionsPopover {
  display: block;
  position: fixed;
  top: 8px;
  left: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 6px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.7) 1px 0px 10px;
  padding: 10px;
}

.card {
  border-style: none;
  background: #f7f7f7;
  border-radius: 0px;
}

.spin {
  animation: infinite-spinning 1s infinite linear;
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}